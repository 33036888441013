<template>
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-search"></i>
    </span>
    <AutoComplete :suggestions="filteredNhms"
                  :min-length="4"
                  :forceSelection="true"
                  :placeholder="t('message.autocomplete.hint-min-chars',{num: 4})"
                  v-model="localValue"
                  v-bind="$attrs"
                  @complete="searchNhmCode($event)">
      <template #item="slotProps">
        <span v-html="highlight(slotProps.item.code, modelValue)"/> - <span
          v-html="splitAndHighlight(slotProps.item.descriptionEn, 100)"></span>
      </template>
    </AutoComplete>
  </div>
  <div v-if="localValue" v-html="truncate(localValue.descriptionEn,300)" class="description"></div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";
import nhmRestService from "@/services/NhmRestService";
import useText from "@/composable/useText";

const {truncate, highlight, splitWithBr, isNotBlank} = useText();

const props = defineProps({
  modelValue: {}
});

const emit = defineEmits(['update:modelValue']);

const {t} = useI18n();
const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});
const filteredNhms = ref();


async function searchNhmCode(e) {
  const term = e.query;
  filteredNhms.value = (await nhmRestService.search(term)).data;
}

function splitAndHighlight(str, maxLength) {
  const highlighted = highlight(str, props.modelValue);
  str = isNotBlank(highlighted) ? highlighted : str;
  return splitWithBr(str, maxLength);
}

</script>

<style scoped>

.description {
  margin-top: 1vh;
  font-size: 1em;
}
</style>
