import _ from "lodash";

export default function () {

    const isBlank = (text) => {
        return null == text || text.trim() === "";
    }

    const isNotBlank = (text) => {
        return !isBlank(text);
    }

    const truncate = (text, length = 10) => {
        return _.truncate(text, {'length': length, 'omission': '...'});
    }

    const toUppercase = (text) => {
        return _.toUpper(text);
    }

    const toLowercase = (text) => {
        return _.toLower(text);
    }

    const splitWithBr = (str, maxLength) => {
        if(isNotBlank(str)) {
            if (str.length <= maxLength) {
                return str;
            } else {
                let reg = new RegExp(".{1," + maxLength + "}", "g");
                let parts = str.match(reg);
                return parts.join('<br/>\n');
            }
        }
    }

    const highlight = (text, term) => {
        if (null != term) {
            let regEx = new RegExp(term, "ig");
            return text.replace(regEx, "<b>" + term + "</b>")
        }
    }

    return {isBlank, isNotBlank, truncate, toUppercase, toLowercase, highlight, splitWithBr}
}
