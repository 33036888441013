<template>
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-search"></i>
    </span>
    <AutoComplete field="descrizione"
                  :suggestions="filteredIncoterms"
                  :min-length="3"
                  :forceSelection="true"
                  :placeholder="t('message.autocomplete.hint-min-chars',{num: 3})"
                  v-model="localValue"
                  v-bind="$attrs"
                  @complete="searchIncoterms($event)">
    </AutoComplete>
  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {useStore} from "vuex";

const props = defineProps({
  modelValue: {}
});

const emit = defineEmits(['update:modelValue']);

const {t} = useI18n();
const store = useStore();

const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const filteredIncoterms = computed(() => store.getters['contratto/incoterms']);

const searchIncoterms = (e) => {
  store.dispatch("contratto/searchIncoterms", e.query);
}

</script>

<style scoped>

</style>
