<template>
  <label :for="id">
    {{ t(labelKey) }}
    <span class="pi pi-question-circle" v-tooltip.top="t(helpKey)" v-if="helpKey"/>
  </label>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();
defineProps({
  id: {},
  labelKey: {},
  helpKey: {type: String, required: false}
});

</script>

<style scoped>

</style>
