<template>
  <div class="grid p-fluid">

    <!-- Riferimenti -->
    <div class="col-12"
         v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER,Roles.EXECUTION_MANAGER, Roles.SPEDIZIONIERE]">
      <Fieldset :legend="t('label.contratto.riferimenti')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- codice -->
          <div class="field col-12 md:col-3">
            <Label id="codice" label-key="label.contratto.riferimenti.numeroContrattoBrokeraggio"/>
            <InputText id="codice" v-model:model-value="item.codice" :class="errorStyleClass(v$, 'codice')"
                       :disabled="allDisabled"/>
            <ErrorMessages field="codice"/>
          </div>

          <!-- codiceRifSeller -->
          <div class="field col-12 md:col-3" v-if="isCodiceRifSellerVisible()">
            <Label id="codiceRifSeller" label-key="label.numeroRiferimento"/>
            <InputText id="codiceRifSeller" v-model:model-value="item.codiceRifSeller"
                       :disabled="!isCodiceRifSellerEditable()"
                       :class="errorStyleClass(v$, 'codiceRifSeller')"/>
            <ErrorMessages field="codiceRifSeller"/>
          </div>

          <!-- codiceRifBuyer -->
          <div class="field col-12 md:col-3" v-if="isCodiceRifBuyerVisible()">
            <Label id="codiceRifBuyer" label-key="label.numeroRiferimento"/>
            <InputText id="codiceRifBuyer" v-model:model-value="item.codiceRifBuyer"
                       :disabled="!isCodiceRifBuyerEditable()"
                       :class="errorStyleClass(v$, 'codiceRifBuyer')"/>
            <ErrorMessages field="codiceRifBuyer"/>
          </div>

          <!-- codiceContrattoForwarding -->
          <div class="field col-12 md:col-3" v-if="isCodiceContrattoForwardingVisible()">
            <Label id="codiceContrattoForwarding" label-key="label.contratto.codice-contratto-forwarding"/>
            <InputText id="codiceContrattoForwarding" v-model:model-value="item.codiceContrattoForwarding"
                       :disabled="!isCodiceContrattoForwardingEditable()"
                       :class="errorStyleClass(v$, 'codiceContrattoForwarding')"/>
            <ErrorMessages field="codiceContrattoForwarding"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- Quantita -->
    <div class="col-12"
         v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER,Roles.EXECUTION_MANAGER, Roles.SPEDIZIONIERE]">
      <Fieldset :legend="t('label.contratto.quantita')" :toggleable="true" :collapsed="allCollapsed">
        <div class="formgroup-inline align-items-center vertical-align-middle">

          <div class="field qualityQuantityRow">
            <span>{{ t('label.contratto.quantita.per-contratto') }}</span>
          </div>

          <!-- quantitaMerce -->
          <div class="field">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">{{ t('label.contratto.quantita.about') }}</span>
              <InputNumber id="quantitaMerce"
                           v-model="item.quantitaMerce"
                           mode="decimal"
                           :min-fraction-digits="2"
                           :max-fraction-digits="2"
                           class="qualityMetricTonsField"
                           :disabled="allDisabled"
                           @focusout="calculatePerTrain"/>
              <span class="p-inputgroup-addon">{{ t('label.contratto.quantita.quantita-merce') }}</span>
            </div>
            <ErrorMessages field="quantitaMerce"/>
          </div>

          <!-- percentualeScostamentoTreni -->
          <div class="field">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">+/-</span>
              <InputNumber v-model="item.percentualeScostamentoTreni"
                           :min="0"
                           :max="100"
                           :min-fraction-digits="0"
                           :max-fraction-digits="0"
                           :disabled="allDisabled"
                           class="qualityPercentField"/>
              <span class="p-inputgroup-addon">%</span>
            </div>
            <ErrorMessages field="percentualeScostamentoTreni"/>
          </div>

          <!-- quantitaACaricoDel -->
          <div class="field">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">{{ t('label.contratto.quantita.at') }}</span>
              <Dropdown v-model="item.quantitaACaricoDel"
                        :options="sellerBuyerOptions"
                        option-label="label"
                        :show-clear="true"
                        :filter="true"
                        option-value="id"
                        :disabled="allDisabled"
                        style="width: 150px"/>
              <span class="p-inputgroup-addon">{{ t('label.contratto.quantita.option-contract-price') }}</span>
            </div>
          </div>

          <!-- quantitaTreni -->
          <div class="field">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">{{ t('label.contratto.quantita.no-block-train') }}</span>
              <InputNumber v-model="item.quantitaTreni"
                           :min-fraction-digits="0"
                           :max-fraction-digits="0"
                           :disabled="allDisabled"
                           class="blockTrainField"
                           @focusout="calculatePerTrain"/>
            </div>
          </div>

        </div>

        <!-- quantitaPerTreni -->
        <div class="formgroup-inline align-content-center vertical-container">
          <div class="field qualityQuantityRow">{{ t('label.contratto.quantita.per-treno') }}</div>
          <div class="field">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">{{ t('label.contratto.quantita.about') }}</span>
              <InputNumber v-model="item.quantitaPerTreno"
                           :min-fraction-digits="0"
                           :max-fraction-digits="0"
                           :disabled="true"
                           class="qualityMetricTonsField"/>
              <span class="p-inputgroup-addon">{{ t('label.contratto.quantita.quantita-merce') }}</span>
            </div>
          </div>
        </div>

      </Fieldset>
    </div>

    <!-- Merce -->
    <div class="col-12"
         v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER,Roles.EXECUTION_MANAGER, Roles.SPEDIZIONIERE]">
      <Fieldset :legend="t('label.contratto.merce')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- descrizioneMerce -->
          <div class="field col-12 md:col-9">
            <Label id="descrizioneMerce" label-key="label.contratto.descrizioneMerce"/>
            <InputText id="descrizioneMerce"
                       v-model="item.descrizioneMerce"
                       :disabled="allDisabled"
                       :class="errorStyleClass(v$, 'descrizioneMerce')"/>
            <ErrorMessages field="descrizioneMerce"/>
          </div>

          <!-- codiceMerce -->
          <div class="field col-12 md:col-3">
            <label for="codiceMerce">{{ t("label.contratto.codiceNhm") }}</label>

            <NhmAutocomplete id="codiceMerce"
                             field="code"
                             :dropdown="false"
                             :forceSelection="true"
                             :multiple="false"
                             :disabled="allDisabled"
                             v-model="item.merce"/>
            <ErrorMessages field="codiceMerce"/>
          </div>

          <!-- dettaglioMerce -->
          <div class="field col-12 md:col-12">
            <label for="dettaglioMerce">{{ t("label.contratto.dettaglioMerce") }}</label>
            <Textarea id="dettaglioMerce"
                      v-model="item.dettaglioMerce"
                      :disabled="allDisabled"
                      rows="6"
                      :class="[errorStyleClass(v$,'dettaglioMerce'), 'p-resize-none']"/>
            <ErrorMessages field="dettaglioMerce"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- Qualita -->
    <div class="col-12" v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER]">
      <Fieldset :legend="t('label.contratto.qualita')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- idTipoControlloQualita -->
          <div class="field col-12 md:col-12">
            <label for="tipoControlloQualita">{{ t("label.contratto.qualita.tipoControlloQualita") }}</label>
            <Dropdown id="tipoControlloQualita"
                      v-model="item.idTipoControlloQualita"
                      :options="qualitaEPesoOpzioni"
                      option-label="descrizione"
                      option-value="codice"
                      :filter="true"
                      :show-clear="true"
                      :disabled="allDisabled"/>
            <ErrorMessages field="idTipoControlloQualita"/>
          </div>

          <!-- dettaglioControlloQualita -->
          <div class="field col-12 md:col-12">
            <label for="dettaglioTipoControlloQualita">{{ t("label.contratto.qualita.note") }}</label>
            <Textarea
                id="dettaglioTipoControlloQualita"
                v-model:model-value="item.dettaglioControlloQualita"
                :disabled="allDisabled"
                rows="4"
                :class="[errorStyleClass(v$,'dettaglioTipoControlloQualita'), 'p-resize-none']"/>
            <ErrorMessages field="dettaglioTipoControlloQualita"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- Pesatura -->
    <div class="col-12" v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER]">
      <Fieldset :legend="t('label.contratto.peso')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!--idOpzioneTipoControlloPeso -->
          <div class="field col-12 md:col-3">
            <label for="opzioneIdTipoControlloPeso">{{ t("label.contratto.peso.opzioni") }}</label>
            <Dropdown id="opzioneIdTipoControlloPeso"
                      v-model="item.opzioneIdTipoControlloPeso"
                      :options="qualitaEPesoOpzioni"
                      option-label="descrizione"
                      option-value="codice"
                      :filter="true"
                      :show-clear="true"
                      :disabled="allDisabled"
                      @change="onPesoOpzioniChange"/>
            <ErrorMessages field="opzioneIdTipoControlloPeso"/>
          </div>

          <!-- idTipoControlloPeso -->
          <div class="field col-12 md:col-3">
            <label for="idTipoControlloPeso">{{ t("label.contratto.peso.tipo") }}</label>
            <Dropdown id="idTipoControlloPeso"
                      :options="pesoTipi"
                      v-model="item.idTipoControlloPeso"
                      option-value="id"
                      option-label="descrizione"
                      :filter="true"
                      :showClear="true"
                      :disabled="allDisabled"
            />
            <ErrorMessages field="idTipoControlloPeso"/>
          </div>

          <!-- dettaglioControlloPeso -->
          <div class="field col-12 md:col-12">
            <label for="dettaglioControlloPeso">{{ t('label.contratto.peso.note') }}</label>
            <Textarea
                id="dettaglioControlloPeso"
                v-model="item.dettaglioControlloPeso"
                rows="4"
                :disabled="allDisabled"
                :class="[errorStyleClass(v$,'dettaglioControlloPeso'), 'p-resize-none']"/>
            <ErrorMessages field="dettaglioControlloPeso"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- Consegna -->
    <div class="col-12"
         v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER,Roles.EXECUTION_MANAGER, Roles.SPEDIZIONIERE]">
      <Fieldset :legend="t('label.contratto.consegna')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- dataInizioConsegna -->
          <div class="field col-12 md:col-3">
            <label for="dataInizioConsegna">{{ t("label.contratto.dal") }}</label>
            <Calendar id="dataInizioConsegna"
                      v-model="item.dataInizioConsegna"
                      dateFormat="dd/mm/yy"
                      :showIcon="true"
                      :disabled="allDisabled"/>
            <ErrorMessages field="dataInizioConsegna"/>
          </div>

          <!-- to -->
          <div class="field col-12 md:col-3">
            <label for="dataLimiteConsegna">{{ t("label.contratto.al") }}</label>
            <Calendar id="dataLimiteConsegna"
                      v-model="item.dataLimiteConsegna"
                      dateFormat="dd/mm/yy"
                      :showIcon="true"
                      :disabled="allDisabled"/>
            <ErrorMessages field="dataLimiteConsegna"/>
          </div>

          <!-- condizioniConsegna -->
          <div class="field col-12 md:col-12">
            <label for="condizioniConsegna">{{ t("label.contratto.consegna.note") }}</label>
            <Textarea id="condizioniConsegna"
                      v-model="item.condizioniConsegna"
                      rows="4"
                      :disabled="allDisabled"/>
            <ErrorMessages field="condizioniConsegna"/>
          </div>
        </div>
      </Fieldset>
    </div>

    <!-- Prezzo e pagamento -->
    <div class="col-12" v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER]">
      <Fieldset :legend="t('label.contratto.prezzo-pagamento')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- prezzo -->
          <div class="field col-12 xl:col-4 md:col-6 sm:col-6">
            <label for="prezzo">{{ t("label.contratto.prezzo") }}</label>
            <div class="p-inputgroup">
                      <span class="p-inputgroup-addon" style="min-width: 10em">
                        <Dropdown v-model="item.valuta"
                                  :options="currencies"
                                  dataKey="id"
                                  option-label="currency"
                                  option-value="iso4217"
                                  :filter="true"
                                  :showClear="true"
                                  :disabled="allDisabled"
                                  style="width: 100%"
                                  @change="onCurrencyChange">
                        </Dropdown>
                      </span>
              <span class="p-inputgroup-addon" v-if="item.valuta" style="min-width: 10em">
                      <InputNumber id="prezzo"
                                   v-model="item.prezzo"
                                   mode="currency"
                                   :currency="item.valuta"
                                   :disabled="allDisabled"/>
                      </span>
              <span class="p-inputgroup-addon" style="min-width: 4em; text-align: center">/ MT</span>
              <ErrorMessages field="prezzo"/>
            </div>
          </div>

          <!-- posizione base -->
          <div class="field col-12 md:col-3">
            <label for="origine">{{ t("label.contratto.prezzo.posizione-base") }}</label>
            <NodoAutocomplete id="origine"
                              v-model="item.posizioneBase"
                              field="descrizione"
                              :disabled="allDisabled"
                              :class="errorStyleClass(v$, 'origine')"/>
          </div>

          <!-- prezzoACaricoDi -->
          <div class="field">
            <label for="prezzoACaricoDi">{{ t('label.at') }}</label>
            <Dropdown id="prezzoACaricoDi"
                      v-model="item.prezzoACaricoDi"
                      :options="sellerBuyerOptions"
                      option-label="label"
                      option-value="id"
                      :filter="true"
                      :showClear="true"
                      :disabled="allDisabled"/>
            <ErrorMessages field="prezzoACaricoDi"/>
          </div>


          <!-- condizioniPrezzo -->
          <div class="field col-12">
            <label for="condizioniPrezzo">{{ t("label.contratto.prezzo.dettagli") }}</label>
            <Textarea id="condizioniPrezzo"
                      v-model="item.condizioniPrezzo"
                      :disabled="allDisabled"
                      :class="[errorStyleClass(v$,'condizioniPrezzo'), 'p-resize-none']" rows="4"/>
            <ErrorMessages field="condizioniPrezzo"/>
          </div>

          <!-- condizioniPagamento -->
          <div class="field col-12">
            <label for="condizioniPagamento">{{ t("label.contratto.prezzo.termini-pagamento") }}</label>
            <Textarea id="condizioniPagamento"
                      v-model="item.condizioniPagamento"
                      :disabled="allDisabled"
                      rows="4"
                      :class="[errorStyleClass(v$,'condizioniPagamento'), 'p-resize-none']"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- trasporto -->
    <div class="col-12" v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER]">
      <Fieldset :legend="t('label.contratto.trasporto')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- traportatoreACaricoDel -->
          <div class="field col-12 md:col-4">
            <label for="traportatoreACaricoDel">{{ t('label.contratto.trasporto.spedizioniere-ferroviario') }}</label>
            <Dropdown id="traportatoreACaricoDel" v-model="item.traportatoreACaricoDel"
                      :options="sellerBuyerOptions"
                      option-label="label"
                      option-value="id"
                      :filter="true"
                      :showClear="true"
                      :disabled="allDisabled"
                      :class="[errorStyleClass(v$,'traportatoreACaricoDel'), 'p-resize-none']"/>
            <ErrorMessages field="traportatoreACaricoDel"/>
          </div>

          <!-- idIncotermTrasporto -->
          <div class="field col-12 md:col-4">
            <label for="idIncotermTrasporto">{{ t('label.contratto.trasporto.incoterms') }}</label>
            <IncotermAutocomplete
                id="idIncotermTrasporto"
                field="descrizione"
                v-model="item.incotermTrasporto"
                :disabled="allDisabled"
                :dropdown="true"
                dropdownMode="blank"/>
            <ErrorMessages field="idIncotermTrasporto"/>
          </div>

          <!-- condizioni trasporto -->
          <div class="field col-12 md:col-12">
            <label for="condizioniTrasporto">{{ t('label.contratto.trasporto.note') }}</label>
            <Textarea id="condizioniTrasporto"
                      v-model="item.condizioniTrasporto"
                      :disabled="allDisabled"
                      :class="[errorStyleClass(v$,'condizioniTrasporto'), 'p-resize-none']"
                      rows="4"/>
            <ErrorMessages field="condizioniTrasporto"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- stazioni ferroviarie -->
    <div class="col-12"
         v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER,Roles.EXECUTION_MANAGER, Roles.SPEDIZIONIERE]">
      <Fieldset :legend="t('label.contratto.stazioni-ferrovia')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- idStazioneOrigine -->
          <div class="field col-12 md:col-12">
            <label for="idStazioneOrigine">{{ t('label.contratto.stazioni-ferrovia.stazione-carico') }}</label>
            <NodoAutocomplete id="idStazioneOrigine"
                              v-model="item.stazioneOrigine"
                              field="descrizione"
                              :disabled="allDisabled"
                              :class="errorStyleClass(v$, 'stazioneOrigine')"/>
            <ErrorMessages field="stazioneOrigine"/>
          </div>

          <!-- note stazione origine -->
          <div class="field col-12 md:col-12">
            <label for="noteStazioneOrigine">{{
                t('label.contratto.stazioni-ferrovia.stazione-carico.note')
              }}</label>
            <Textarea id="noteStazioneOrigine"
                      v-model="item.noteStazioneOrigine"
                      :disabled="allDisabled"
                      rows="4"
                      :class="[errorStyleClass(v$, 'condizioniDogana'), 'p-resize-none']"/>
            <ErrorMessages field="noteStazioneOrigine"/>
          </div>

          <!-- idStazioneDestinazione -->
          <div class="field col-12 md:col-12">
            <label for="idStazioneDestinazione">{{ t('label.contratto.stazioni-ferrovia.stazione-scarico') }}</label>
            <NodoAutocomplete id="idStazioneDestinazione"
                              v-model="item.stazioneDestinazione"
                              field="descrizione"
                              :disabled="allDisabled"
                              :class="errorStyleClass(v$, 'stazioneDestinazione')"/>
            <ErrorMessages field="stazioneDestinazione"/>
          </div>

          <!-- note stazione destinazione -->
          <div class="field col-12 md:col-12">
            <label for="noteStazioneDestinazione">{{
                t('label.contratto.stazioni-ferrovia.stazione-scarico.note')
              }}</label>
            <Textarea id="noteStazioneDestinazione"
                      v-model:model-value="item.noteStazioneDestinazione"
                      :disabled="allDisabled"
                      rows="4"
                      :class="[errorStyleClass(v$,'noteStazioneDestinazione'), 'p-resize-none']"/>
            <ErrorMessages field="noteStazioneDestinazione"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- Condizioni dogana -->
    <div class="col-12" v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER]">
      <Fieldset :legend="t('label.contratto.dogana')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- doganaACaricoDel -->
          <div class="field col-12 md:col-12">
            <label for="doganaACaricoDel">{{ t('label.contratto.dogana.documento-sdogamanto') }}</label>
            <Dropdown id="doganaACaricoDel"
                      v-model="item.doganaACaricoDel"
                      :options="sellerBuyerOptions"
                      option-label="label"
                      option-value="id"
                      :filter="true"
                      :show-clear="true"
                      :disabled="allDisabled"
                      style="width: 150px"/>
            <ErrorMessages field="doganaACaricoDel"/>
          </div>

          <!-- condizioni dogana -->
          <div class="field col-12 md:col-12">
            <label for="condizioniDogana">{{ t('label.contratto.dogana.note') }}</label>
            <Textarea id="condizioniDogana"
                      v-model="item.condizioniDogana"
                      :disabled="allDisabled"
                      :class="[errorStyleClass(v$,'condizioniDogana'), 'p-resize-none']" rows="4"/>
            <ErrorMessages field="condizioniDogana"/>
          </div>

        </div>
      </Fieldset>
    </div>

    <!-- Note -->
    <div class="col-12" v-permission="[Roles.ADMIN,Roles.BROKER, Roles.BUYER, Roles.SELLER]">
      <Fieldset :legend="t('label.note')" :toggleable="true" :collapsed="allCollapsed">
        <div class="p-fluid formgrid grid">

          <!-- condizioniSpeciali -->
          <div class="field col-12 md:col-12">
            <label for="condizioniSpeciali">{{ t('label.contratto.condizioni-speciali.note') }}</label>
            <Textarea id="condizioniSpeciali"
                      v-model="item.condizioniSpeciali"
                      :disabled="allDisabled"
                      :class="[errorStyleClass(v$,'condizioniSpeciali'), 'p-resize-none']" rows="4"/>
            <ErrorMessages field="condizioniSpeciali"/>
          </div>

          <!-- condizioniGenerali -->
          <div class="field col-12 md:col-12">
            <label for="condizioniGenerali">{{ t('label.contratto.condizioni-generali.note') }}</label>
            <Textarea id="condizioniGenerali"
                      v-model:model-value="item.condizioniGenerali"
                      :disabled="allDisabled"
                      :class="[errorStyleClass(v$,'condizioniGenerali'), 'p-resize-none']" rows="4"/>
            <ErrorMessages field="condizioniGenerali"/>
          </div>

          <!-- clausolaArbitrale -->
          <div class="field col-12 md:col-12">
            <label for="clausolaArbitraleNote">{{ t('label.contratto.clausula-arbitrale.note') }}</label>
            <Textarea id="clausolaArbitraleNote"
                      v-model="item.clausolaArbitrale"
                      :disabled="allDisabled"
                      :class="[errorStyleClass(v$,'clausolaArbitrale'), 'p-resize-none']" rows="4"/>
            <ErrorMessages field="clausolaArbitrale"/>
          </div>

        </div>
      </Fieldset>
    </div>


  </div>
</template>

<script setup>
import {computed, onMounted, onUnmounted, provide, ref, watch} from "vue";
import {helpers, required} from "@vuelidate/validators";
import useCommon from "@/composable/useCommon";
import useVuelidate from "@vuelidate/core";
import ErrorMessages from "@/components/common/ErrorMessages";
import eventService from "@/services/EventService";
import contrattoRestService from "@/services/ContrattoRestService";

import moment from "moment";
import Roles from "@/components/common/constants/Roles";
import {isEmpty, isNull, isNumber} from "lodash/lang";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import IncotermAutocomplete from "@/components/common/autocomplete/IncotermAutocomplete";
import NodoAutocomplete from "@/components/common/autocomplete/NodoAutocomplete";
import Label from "@/components/common/Label";
import useMessage from "@/composable/useMessage";
import NhmAutocomplete from "@/components/common/autocomplete/NhmAutocomplete";
import tipoControlloPesoRestService from "@/services/TipoControlloPesoRestService";
import tipoControlloQualitaRestService from "@/services/TipoControlloQualitaRestService";

const {t} = useI18n();
const store = useStore();
const {errorStyleClass} = useCommon();
const {error} = useMessage();

const props = defineProps({
  contratto: {type: Object, required: true}
});

const emit = defineEmits(['update:contratto']);

//const LOADING_ID = "loading";
//const UNLOADING_ID = "unloading";
const EUR_CURRENCY = "EUR";

let allCollapsed = false;

const item = computed({
  get: () => props.contratto,
  set: (value) => emit('update:contratto', value)
});


let allDisabled = computed(() => {
  const isBuyer = store.getters["auth/isBuyer"];
  const isSeller = store.getters['auth/isSeller'];
  const isExecutionManager = store.getters['auth/isExecutionManager'];
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  return isBuyer || isSeller || isExecutionManager || isSpedizioniere;
});

// common
const sellerBuyerOptions = ref([
  {id: Roles.BUYER, label: t('label.buyer')},
  {id: Roles.SELLER, label: t('label.seller')}
]);


// quality
const qualitaEPesoOpzioni = ref([]);

// peso
const pesoTipi = ref([]);

// currencies
const currencies = computed(() => store.getters['contratto/currencies']);
watch(currencies, () => {
  if (!isEmpty(currencies.value) && isEmpty(item.value)) {
    const euroCurrencyArray = currencies.value.filter(c => EUR_CURRENCY === c.iso4217);
    const isEuroPresent = !isEmpty(euroCurrencyArray);
    const aCurrency = isEuroPresent ? euroCurrencyArray[0] : currencies.value[0];
    item.value.valuta = aCurrency.iso4217
  }
});


// validation rules
const rules = {
  codice: {
    required: helpers.withMessage(t("validation.required", {field: t("label.contratto.riferimenti.numeroContrattoBrokeraggio")}), required),
  },
  stazioneOrigine: {
    required: helpers.withMessage(t("validation.required", {field: t("label.contratto.stazioni-ferrovia.stazione-carico")}), required)
  },
  stazioneDestinazione: {
    required: helpers.withMessage(t("validation.required", {field: t("label.contratto.stazioni-ferrovia.stazione-scarico")}), required)
  },
  traportatoreACaricoDel: {
    required: helpers.withMessage(t("validation.required", {field: t("label.contratto.trasporto.spedizioniere-ferroviario")}), required)
  }
}
const v$ = useVuelidate(rules, item);

const validateContratto = () => {
  v$.value.$validate();
  if (!v$.value.$invalid) {

    // TODO: START logiche riferimenti / oggetti da rivedere
    // le api rest hanno subito numerose modifiche per cui deve essere allineato il formato degli oggetti usati o dei
    // riferimenti, ad esempio nei componenti autocomplete
    remapDependencies();
    eventService.emit("contratto-valid", item.value);
  } else {
    error(t('validation.errors'));
  }
}

function contrattoData() {
  remapDependencies();
  eventService.emit("on-contratto-data", item.value);
}

const remapDependencies = () => {

  // codiceMerce
  if (!isEmpty(item.value.merce)) {
    item.value.codiceMerce = item.value.merce.code;
  }

  // origine
  if (!isEmpty(item.value.posizioneBase)) {
    item.value.idPosizioneBase = item.value.posizioneBase.id;
  }

  // incotermTrasporto
  if (!isEmpty(item.value.incotermTrasporto)) {
    item.value.idIncotermTrasporto = item.value.incotermTrasporto.id;

  }
  // stazioneOrigine
  if (!isEmpty(item.value.stazioneOrigine)) {
    item.value.idStazioneOrigine = item.value.stazioneOrigine.id;

  }
  // stazioneDestinazione
  if (!isEmpty(item.value.stazioneDestinazione)) {
    item.value.idStazioneDestinazione = item.value.stazioneDestinazione.id;
  }
}

onMounted(async () => {
  await store.dispatch("contratto/loadCurrencies");

  eventService.on("contratto-validate", validateContratto);
  eventService.on("contratto-data", contrattoData);

  // quantitaMerce (string to float)
  if (!isEmpty(item.value.quantitaMerce)) {
    item.value.quantitaMerce = parseFloat(item.value.quantitaMerce);
  }

  // reload dependent dropdown
  if (!isEmpty(item.value.opzioneIdTipoControlloPeso)) {
    await onPesoOpzioniChange({value: item.value.opzioneIdTipoControlloPeso});
  }

  // data inizio e data limite consegna
  if (!isEmpty(item.value.dataInizioConsegna)) {
    item.value.dataInizioConsegna = moment(item.value.dataInizioConsegna).toDate();
  }

  if (!isEmpty(item.value.dataLimiteConsegna)) {
    item.value.dataLimiteConsegna = moment(item.value.dataLimiteConsegna).toDate();
  }

  // init qualita e peso azioni
  qualitaEPesoOpzioni.value = (await tipoControlloQualitaRestService.findAll());

});

onUnmounted(() => {
  eventService.off("contratto-validate", validateContratto);
  eventService.off("contratto-data", contrattoData);
});


async function calculatePerTrain() {
  const quantitaMerce = item.value.quantitaMerce;
  const quantitaTreni = item.value.quantitaTreni;
  const isQuantitaMerceConsistent = (!isNull(quantitaMerce) && isNumber(quantitaMerce) && quantitaMerce > 0);
  const isNoBlockTrainsConsistent = (!isNull(quantitaTreni) && isNumber(quantitaTreni) && quantitaTreni > 0);
  if (isQuantitaMerceConsistent && isNoBlockTrainsConsistent) {
    item.value.quantitaPerTreno = await contrattoRestService.computeQuantityPerTrain(quantitaMerce, quantitaTreni);
  }
}

async function onPesoOpzioniChange(e) {
  pesoTipi.value = [];
  if (null != e.value && '' !== e.value) {
    pesoTipi.value = await tipoControlloPesoRestService.findByContesto(e.value);
  }
}


function onCurrencyChange(e) {
  item.value.valuta = e.value;
}


// region Field visibility
// TODO: queste logiche vanno spostate lato server

const isCodiceRifBuyerVisible = () => {
  const isBuyer = store.getters['auth/isBuyer'];
  const isCodiceRifBuyerSet = !isEmpty(item.value.codiceRifBuyer) && (Roles.BUYER === item.value.traportatoreACaricoDel);
  const isExManAndCodRifBuyerIsSet = store.getters['auth/isExecutionManager'] && isCodiceRifBuyerSet;
  const isSpedizioniereAndCodRifBuyerIsSet = store.getters['auth/isSpedizioniere'] && isCodiceRifBuyerSet;
  return isBuyer || isExManAndCodRifBuyerIsSet || isSpedizioniereAndCodRifBuyerIsSet;
}

const isCodiceRifBuyerEditable = () => {
  return store.getters['auth/isBuyer'];
}

const isCodiceRifSellerVisible = () => {
  const isSeller = store.getters['auth/isSeller'];
  const isCodiceRifSellerSet = !isEmpty(item.value.codiceRifSeller) && (Roles.SELLER === item.value.traportatoreACaricoDel);
  const isExManAndCodRifSellerIsSet = store.getters['auth/isExecutionManager'] && isCodiceRifSellerSet;
  const isSpedizioniereAndCodRifSellerIsSet = store.getters['auth/isSpedizioniere'] && isCodiceRifSellerSet;
  return isSeller || isExManAndCodRifSellerIsSet || isSpedizioniereAndCodRifSellerIsSet;
}

const isCodiceRifSellerEditable = () => {
  return store.getters['auth/isSeller'];
}

const isCodiceContrattoForwardingVisible = () => {
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  const isExecutionManager = store.getters['auth/isExecutionManager'];
  return isSpedizioniere || isExecutionManager
}

const isCodiceContrattoForwardingEditable = () => {
  return store.getters['auth/isSpedizioniere'];
}

// endregion

provide("v$", v$);
</script>

<style scoped>

.qualityQuantityRow {
  width: 80px;
}

.qualityMetricTonsField {
  width: 100px
}

.qualityPercentField {
  width: 50px
}

.blockTrainField {
  width: 50px
}

</style>
